import { AuthService } from "services/auth-service";
import { autoinject } from 'aurelia-framework';
import { ImpiloRepository } from "services/repositories";

@autoinject()
export class Index {

    constructor(private readonly authService: AuthService, private readonly repository: ImpiloRepository) {
    }

    async activate() {
        await this.authService.getCurrentPharmacy();
    }

    async weeklyPackList() {
        await this.repository.DownloadToBePackedForWeek(new Date(), 'To Be Packed.pdf');
    }

    async medicationScheduled() {
        await this.repository.downloadMedicationScheduled();
    }

    async medicationUsage() {
        await this.repository.downloadMedicationUsage();
    }


    get showScreen() {
        return !this.showScreenInactivePharmacy;
    }

    get showScreenInactivePharmacy() {
        return !this.authService.isPharmacyActive();
    }
}
