import { DialogController } from 'aurelia-dialog';
import { autoinject, computedFrom } from 'aurelia-framework';
import { ImpiloRepository, ImpiloDb } from 'services/repositories';
import { ToastrService } from 'services/toastr-service';
import { PrintService } from 'services/print-service';

@autoinject()
export class PrintDialog {
    model: {

    };

    title = "Treatment Plan Progress";

    constructor(private readonly repository: ImpiloRepository, private readonly printService: PrintService, private readonly dialogController: DialogController) {
    }

    ok() {
        this.dialogController.ok();
    }

    cancel() {
        this.dialogController.cancel();
    }

    activate(model) {
        this.model = model;

        this.printService.print(() => {
            this.dialogController.ok();
        });

        queueMicrotask(() => this.title = this.printService.printDialogTitle);
    }

    public get guid(): string {
        return this.printService.guid;
    }

    public get patientProgress(): string {
        return this.printService.patientProgress;
    }

    public get patientProgressStyle() {
        return `width: ${this.printService.patientPercentage}%`;
    }

    public get dateOverlayPercentage(): string {
        return this.printService.dateOverlayPercentage.toFixed(0);
    }

    public get dateOverlayPercentageStyle() {
        return `width: ${this.printService.dateOverlayPercentage}%`;
    }

    public get uploadingPercentage(): string {
        return this.printService.uploadingPercentage.toFixed();
    }

    public get uploadingPercentageStyle() {
        return `width: ${this.printService.uploadingPercentage}%`;
    }

    public get message(): string {
        return this.printService.message;
    }

    get canCancel() {
        return this.printService.uploadingPercentage == 0;
    }

    deactivate() {
        //
    }
}


