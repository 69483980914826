import { autoinject, bindable, DOM, observable, TemplatingEngine } from 'aurelia-framework';
import flatpickr from 'flatpickr';
import { PrintService } from 'services/print-service';

@autoinject()
export class Options {

    week1End: Date;
    week1EndS: string;

    week2Start: Date;
    week2StartS: string;

    week2End: Date;
    week2EndS: string;

    week3Start: Date;
    week3StartS: string;

    week3End: Date;
    week3EndS: string;

    week4Start: Date;
    week4StartS: string;

    week4End: Date;
    week4EndS: string;

    printLabelNoLogo = false;

    @observable selectedChoice = 0;

    flatpickrWeek1StartConfig: flatpickr.Options.Options = {
        weekNumbers: true,
        'disable': [
            (date) => {
                return (date.getDay() !== 1);
            }
        ],
    };

    constructor(private readonly printService: PrintService) {
        printService.reset();

        this.week1Start = printService.week1Start;
    }

    public get week1Start(): Date {
        return this.printService.week1Start;
    }
    public set week1Start(value: Date) {
        // console.log(value);
        this.printService.week1Start = getTimezoneOffset(value);
        this.setDates(value);
    }

    public get printWeek1(): boolean {
        return this.printService.printWeek1;
    }
    public set printWeek1(value: boolean) {
        this.printService.printWeek1 = value;
    }

    public get printWeek2(): boolean {
        return this.printService.printWeek2;
    }
    public set printWeek2(value: boolean) {
        this.printService.printWeek2 = value;
    }

    public get printWeek3(): boolean {
        return this.printService.printWeek3;
    }
    public set printWeek3(value: boolean) {
        this.printService.printWeek3 = value;
    }

    public get printWeek4(): boolean {
        return this.printService.printWeek4;
    }
    public set printWeek4(value: boolean) {
        this.printService.printWeek4 = value;
    }

    selectedChoiceChanged(newValue, oldValue) {
        if (newValue !== undefined) {
            this.printService.selectedChoice = newValue;
        }
    }

    public get printPatientsConsolidate() {
        return this.printService.printPatientsConsolidate;
    }
    public set printPatientsConsolidate(value: boolean) {
        this.printService.printPatientsConsolidate = value;
    }

    get updateLastPrinted(): boolean {
        return this.printService.updateLastPrinted;
    }
    set updateLastPrinted(update: boolean) {
        this.printService.updateLastPrinted = update;
    }

    setDates(date: Date) {

        const sixDays = (1000 * 60 * 60 * 24 * 6);

        this.week1End = new Date(date.getTime() + sixDays);
        this.week1EndS = flatpickr.formatDate(this.week1End, 'd/m/Y');

        this.week2Start = this.printService.getNextMon(date);
        this.week2StartS = flatpickr.formatDate(this.week2Start, 'd/m/Y');

        this.week2End = new Date(this.week2Start.getTime() + sixDays);
        this.week2EndS = flatpickr.formatDate(this.week2End, 'd/m/Y');

        this.week3Start = this.printService.getNextMon(this.week2Start);
        this.week3StartS = flatpickr.formatDate(this.week3Start, 'd/m/Y');

        this.week3End = new Date(this.week3Start.getTime() + sixDays);
        this.week3EndS = flatpickr.formatDate(this.week3End, 'd/m/Y');

        this.week4Start = this.printService.getNextMon(this.week3Start);
        this.week4StartS = flatpickr.formatDate(this.week4Start, 'd/m/Y');

        this.week4End = new Date(this.week4Start.getTime() + sixDays);
        this.week4EndS = flatpickr.formatDate(this.week4End, 'd/m/Y');
    }
}

function getTimezoneOffset(date) {
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
}