import { GenericEntitySearch } from "@resources/utils/generic-entity-search";
import { autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";
import {
  AureliaGridInstance,
  Column,
  FieldType,
  Formatter,
  Formatters,
  GridOption,
  SelectedRange,
} from "aurelia-slickgrid";
import { IEntity, ResultPager } from "plugins/data-models";
import { ImpiloRepository } from "services/repositories";
import { PrintService } from "services/print-service";
import { AuthService } from "services/auth-service";

const linkButtonFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: any) => {
  return `<button type="button" class="btn btn-link" style="padding-top: 0;">Download</button>`;
};

@autoinject()
export class Index extends GenericEntitySearch {
  constructor(private readonly repository: ImpiloRepository, private readonly router: Router, private readonly printService: PrintService, private readonly authService: AuthService) {
    super();

    this.pager = new ResultPager(async (page: number, size: number) => {
      if (this.filter.trim().length >= 0) {
        this.isWaiting = true;
        const response = await this.repository.getTreatmentPlanLog(
          null,
          page,
          size
        );
        this.isWaiting = false;

        this.gridDataset = response.results.map((entity) => {
          return entity;
        });

        return response;
      }
    });
  }

  async activate() {
    await this.authService.getCurrentPharmacy();
  }

  get showScreen() {
    return !this.showScreenInactivePharmacy;
  }

  get showScreenInactivePharmacy() {
    return !this.authService.isPharmacyActive();
  }

  // abstract defineGrid();
  defineGrid() {
    this.gridColumns = [
      { id: "date", name: "Date", field: "date", sortable: true, formatter: Formatters.dateIso },
      { id: "printType", name: "Type", field: "printType", sortable: true },
      { id: "week1", name: "Week 1", field: "week1", formatter: Formatters.checkmark },
      { id: "week2", name: "Week 2", field: "week2", formatter: Formatters.checkmark },
      { id: "week3", name: "Week 3", field: "week3", formatter: Formatters.checkmark },
      { id: "week4", name: "Week 4", field: "week4", formatter: Formatters.checkmark },
      { id: "createdAt", name: "Created", field: "createdAt", sortable: true, formatter: Formatters.dateTimeIso },
      {
        id: "download", name: "Download", field: "download", formatter: linkButtonFormatter, onCellClick: async (e, args) => {

          const guid = args.dataContext.guid;
          const type = args.dataContext.printType;
          console.log(guid, type);
          await this.printService.downloadPrintWeekLog(guid, type);
        }
      },
    ];

    this.gridOptions = {
      autoResize: {
        containerId: 'container-grid',
        calculateAvailableSizeBy: 'window',
        bottomPadding: 65
    },
      enableGridMenu: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      checkboxSelector: {
        hideSelectAllCheckbox: true,
      },
      rowSelectionOptions: {
        selectActiveRow: true,
      }
    };
  }

  attachedDone() {
    if (this.pager) {
      return this.pager.gotoFirst();
    }
  }

  // abstract editEntity(entity: IEntity): void;
  editEntity(entity: IEntity) {
    //
  }

  // abstract newEntity(): void;
  newEntity() {
    //
  }

  // abstract handleRowSelection(event, args);
  handleRowSelection(event, args) {
    //
  }
}
