import { autoinject, TemplatingEngine } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { Router, RouterConfiguration } from 'aurelia-router';
import { deflate } from 'pako';
import { AuthService } from 'services/auth-service';
import { ImpiloRepository } from 'services/repositories';

export interface IChoice {
    id: number;
    name: string;
}

@autoinject()
export class Index {

    private router: Router;
    isPharmacyAllowedTo: { isAllowed: boolean, problem: string, solution: string }

    constructor(private readonly repository: ImpiloRepository, private readonly templatingEngine: TemplatingEngine, private readonly authService: AuthService) {
    }

    configureRouter(config: RouterConfiguration, router: Router) {
        config.title = '';
        config.map([
            { route: '', redirect: 'search' },
            { route: 'search', name: 'search', moduleId: PLATFORM.moduleName('./search/index'), title: 'Search', nav: true },
            { route: 'history', name: 'history', moduleId: PLATFORM.moduleName('./history/index'), title: 'History', nav: true },
        ]);
        this.router = router;
    }

    get canPrint() {
        return this.isPharmacyAllowedTo.isAllowed;
    }

    navigateToAccount() {
        this.router.navigateToRoute('account');
    }

    async activate() {
        await this.authService.getCurrentPharmacy();
        this.isPharmacyAllowedTo = await (this.repository as ImpiloRepository).isPharmacyAllowedTo('print');
    }

    get showScreen() {
        return !this.showScreenInactivePharmacy;
    }

    get showScreenInactivePharmacy() {
        return !this.authService.isPharmacyActive();
    }

    async downloadPdf(ele: Element): Promise<{ blob: Blob; fileName: string }> {

        const html = deflate(ele.innerHTML);

        const formData = new FormData();

        const file = new Blob([html], { type: 'application/zlib' });
        formData.append('html', file);

        formData.append('compressed', 'true');

        const awaited = await this.repository.http.fetch('/HtmlToPdf', {
            body: formData,
            method: 'POST'
        });
        const blob = await awaited.blob();

        const fileName = awaited.headers.get('Content-Disposition').split(';')[1].split('=')[1];

        return { blob, fileName };
    }

    savePdfBlob(blob) {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([blob.blob], { type: 'application/pdf' });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = data;
        link.download = `${blob.fileName}.pdf`;
        link.click();
        setTimeout(() => { window.URL.revokeObjectURL(data); }, 100);
    }
}
