import { GenericEntitySearch } from '@resources/utils/generic-entity-search';
import { autoinject, observable, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { AureliaGridInstance, Formatters } from 'aurelia-slickgrid';
import { IEntity, ResultPager } from 'plugins/data-models';
import { AuthService } from 'services/auth-service';
import { ImpiloDb, ImpiloRepository } from 'services/repositories';
import { DialogService } from 'aurelia-dialog';
import { PrintDialog } from '../dialog/print';
import { PrintService } from 'services/print-service';

@autoinject()
export class Index extends GenericEntitySearch {

    selectedPatients: any[];
    gridInstance: AureliaGridInstance;

    private careHomes: ImpiloDb.Tables.CareHome[] = [];
    private subGroups: ImpiloDb.Tables.SubGroup[] = [];
    private packingGroups: ImpiloDb.Tables.PackingGroup[] = [];

    @observable private selectedCareHome: Partial<ImpiloDb.Tables.CareHome> = null;
    @observable private selectedSubGroup: Partial<ImpiloDb.Tables.SubGroup> = null;
    @observable private selectedPackingGroup: Partial<ImpiloDb.Tables.PackingGroup> = null;

    @observable isActive = true;

    constructor(private readonly repository: ImpiloRepository, private readonly printService: PrintService, private readonly dialogService: DialogService, private readonly authService: AuthService) {
        super();

        this.pager = new ResultPager(async (page: number, size: number) => {

            this.selectedPatients = [];
            this.gridInstance?.slickGrid.setSelectedRows([]);

            const filter = this.filter;

            if (this.filter.trim().length >= 0) {

                this.isWaiting = true;
                const response = await this.repository.filterPatient(filter, this.isActive, this.selectedCareHome?.guid, this.selectedSubGroup?.guid, this.selectedPackingGroup?.guid, page, size);
                this.isWaiting = false;

                this.gridDataset = response.results.map((patient: ImpiloDb.Tables.Patient) => {
                    return patient;
                });

                return response;
            }
        });

        this.pager.itemsPerPage = 500;
    }

    async activate() {
        this.printService.updateLastPrinted = true;
        await this.authService.getCurrentPharmacy();
    }

    get showScreen() {
        return !this.showScreenInactivePharmacy;
    }

    get showScreenInactivePharmacy() {
        return !this.authService.isPharmacyActive();
    }

    async attachedDone() {
        if (this.pager) {

            const packingGroups = (await (this.repository as ImpiloRepository).getPackingGroups()).results.map((value) => {
                value.option = value.name;
                return value;
            }).sort((a, b) => a.name.localeCompare(b.name));
            this.packingGroups = packingGroups;

            const careHomes = (await (this.repository as ImpiloRepository).getCareHomes()).results.map((careHome) => {
                careHome.option = careHome.name;
                return careHome;
            }).filter((a) => a.isActive).sort((a, b) => a.name.localeCompare(b.name));
            this.careHomes = careHomes;

            return this.pager.gotoFirst();
        }
    }

    async selectedCareHomeChanged(newValue, oldValue) {
        if (newValue && oldValue && newValue.guid === oldValue.guid) {
            // do nothing
        } else if (newValue?.guid) {
            // console.log(newValue);
            this.subGroups = (await (this.repository as ImpiloRepository).getSubGroups(newValue.guid)).results.map((subgroup) => {
                subgroup.option = subgroup.name;
                return subgroup;
            }).filter((a) => a.isActive).sort((a, b) => a.name.localeCompare(b.name));
        } else {
            if (this.subGroups) {
                this.subGroups.splice(0);
            }
        }

        if (this.pager) {
            return this.pager.gotoFirst();
        }
    }


    selectedSubGroupChanged(newValue, oldValue) {
        if (this.pager) {
            return this.pager.gotoFirst();
        }
    }

    selectedPackingGroupChanged(newValue, oldValue) {
        if (this.pager) {
            return this.pager.gotoFirst();
        }
    }

    isActiveChanged() {
        if (this.pager) {
            return this.pager.gotoFirst();
        }
    }

    @computedFrom('selectedPackingGroup')
    get packingGroupSelected() {
        return this.selectedPackingGroup !== null;
    }

    @computedFrom('selectedCareHome')
    get careHomeSelected() {
        return this.selectedCareHome !== null;
    }

    defineGrid() {
        this.gridColumns = [
            { id: 'title', name: 'Title', field: 'title.name', sortable: true, formatter: Formatters.complexObject },
            { id: 'firstName', name: 'First Name', field: 'firstName', sortable: true },
            { id: 'surname', name: 'Surname', field: 'surname', sortable: true },
            { id: 'careHome', name: 'Care Home', field: 'careHome.name', sortable: true, formatter: Formatters.complexObject },
            { id: 'subGroup', name: 'Sub Group', field: 'subGroup.name', sortable: true, formatter: Formatters.complexObject },
            { id: 'lastPrinted', name: 'Last Printed', field: 'lastPrinted', sortable: false },
        ];

        this.gridOptions = {
            autoResize: {
                containerId: 'container-grid',
                calculateAvailableSizeBy: 'window',
                bottomPadding: 65
            },
            enableGridMenu: false,
            enableCellNavigation: true,
            enableRowSelection: true,
            enableCheckboxSelector: true,
            checkboxSelector: {
                hideSelectAllCheckbox: false
            },
            rowSelectionOptions: {
                selectActiveRow: false,
            }
        };
    }

    // abstract editEntity(entity: IEntity): void;
    editEntity(entity: IEntity) {
        //
    }

    // abstract newEntity(): void;
    newEntity() {
        //
    }

    aureliaGridCreated(event) {
        this.gridInstance = event;
        this.gridInstance.dataView.syncGridSelection(this.gridInstance.slickGrid, true);
    }

    // abstract handleRowSelection(event, args);
    handleRowSelection(event, args) {
        const grid = args && args.grid;
        if (grid && Array.isArray(args.rows)) {
            this.selectedPatients = args.rows.map(idx => {
                return grid.getDataItem(idx);
            });
        }
    }

    @computedFrom('selectedPatients.length')
    get canPrint(): boolean {

        return this.selectedPatients && this.selectedPatients.length > 0;
    }

    async printDialog() {

        if (this.selectedPatients.length > 0) {
            if (!this.dialogService.hasOpenDialog) {

                this.printService.patientIds = this.selectedPatients.map(v => v.id);

                const result = await this.dialogService.open({
                    viewModel: PrintDialog,
                    model: { title: this.printService.printDialogTitle },
                    lock: true,
                    keyboard: true,
                    startingZIndex: 99999
                }).whenClosed((value) => {
                    if (!value.wasCancelled) {
                        //
                    }
                });
            }
        }
    }

    private readonly idMatcher = (a, b) => {
        if (a && b) {
            return a.id === b.id;
        }

        return false;
    }
}
